<div class="alignButton">
  <button [attr.id]="'btnDialogClose'" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<h2 mat-dialog-title>{{ 'codebook.title' | translate }}</h2>

<div class="content-height" mat-dialog-content>
  <p>{{ 'codebook.overview_codebook' | translate }}</p>

  <app-codebook-overview [_study]="study"></app-codebook-overview>
</div>
