/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { filter, mergeMap, take } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { InterventionConfigurationInterface } from '../../../models/interface/intervention-configuration.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { Store } from '@ngrx/store';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { getCollabInterventionById } from '../../../store/intervention/intervention.selector';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-instance-questionnaire-configuration-update',
  templateUrl: './configuration-update.component.html',
  styleUrls: ['./configuration-update.component.scss']
})
export class ConfigurationUpdateComponent implements OnInit, OnDestroy {
  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faBook = faBook;
  faEye = faEye;
  faSquare = faSquare;

  public instance: InterventionInstanceInterface;
  public lockedQuestionnaireIds: Array<number> = [];
  public intervention: InterventionInterface;
  // Selected guiding type
  public selectedGuidanceType = 'UNGUIDED';

  // Questionnaires of intervention
  public questionnaires: Array<LessonInterface> = [];

  public intervention$: Observable<InterventionInterface | undefined>;

  public skills: Array<SkillInterface> = [];

  // Diaries of intervention
  public diaries: Array<DiaryInterface> = [];

  public unlockType: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE'];
  public unlockTypeFirst: Array<string> = ['ALWAYS', 'AT_DATE'];
  public unlockTypeFirstNew: Array<string> = ['ALWAYS', 'AT_DATE', 'CONDITIONAL'];
  public unlockTypeNext: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE'];
  public unlockTypeNew: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE', 'CONDITIONAL'];

  // Instance creation instanceForm
  public instanceFormType: UntypedFormGroup;
  public currentDate = this.helperService.formatDateToYYYY_MM_DD_THH_MM(Date.now());

  public formCustomOrderSubject = new BehaviorSubject<Array<{ questionnaire: number; enabled: boolean }>>([]);
  updateView: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showMoveShortCutSubject: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>([]);

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  private defaultConfiguration: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };
  private defaultConfigurationReadOnly: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };
  private unlockingLessons = new Map<number, number[]>();
  private actualConditionalLessons = new Map<number, boolean>();

  private subscriptions: Subscription[] = [];

  constructor(
    private rootFormGroup: FormGroupDirective,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private helperSkillService: HelperSkillService,
    private store: Store<{ getCollabInterventionById: InterventionInterface }>
  ) {}

  public get f() {
    return this.instanceFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get fQuestionnaireConfiguration() {
    return this.instanceFormType.get('questionnaire_configuration') as UntypedFormArray;
  }

  // Set study and its configuration from parent
  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.defaultConfigurationReadOnly = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    this.intervention = JSON.parse(JSON.stringify(_intervention));
    this.defaultConfiguration = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));

    if (this.intervention.attributes.intervention_type === 'accompanied') {
      if (!this.instanceFormType) {
        this.instanceFormType = this.rootFormGroup.control;
      }
      this.instanceFormType.controls['intervention_type'].setValue('GUIDED', { onlySelf: true });
      this.selectedGuidanceType = 'GUIDED';
    }
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    if (_questionnaires) {
      this.questionnaires = _questionnaires;
    }
  }

  @Input()
  set _skills(_skills: Array<SkillInterface>) {
    if (_skills) {
      this.skills = _skills;
    }
  }

  @Input()
  set _diaries(_diaries: Array<DiaryInterface>) {
    if (_diaries) {
      this.diaries = _diaries;
    }
  }

  @Input()
  set _form_custom_orderSubject(_formCustomOrderSubject: BehaviorSubject<Array<{ questionnaire: number; enabled: boolean }>>) {
    if (_formCustomOrderSubject) {
      this.formCustomOrderSubject = _formCustomOrderSubject;
    }
  }

  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    if (_instance) {
      this.instance = _instance;
    }
  }

  @Input()
  set _locked_questionnaire_ids(_lockedQuestionnaireIds: Array<number>) {
    if (_lockedQuestionnaireIds) {
      this.lockedQuestionnaireIds = _lockedQuestionnaireIds;
    }
  }

  ngOnInit(): void {
    this.instanceFormType = this.rootFormGroup.control;
    this.instanceFormType.controls['starts_at'].valueChanges.subscribe((_value) => {
      const startsAt = this.helperService.convertStringToUnixTimestamp(this.f.starts_at.value);
      this.fQuestionnaireConfiguration.controls.forEach(formcontrol => {
        const unlockDate = this.helperService.convertStringToUnixTimestamp(new Date(formcontrol.get('unlock_date').value).toUTCString());
        if (unlockDate < startsAt) {
          formcontrol.get('unlock_date').patchValue(this.helper.formatDateToYYYY_MM_DD_THH_MM(startsAt * 1000), { onlySelf: true });
        }
      });
    });
    this.getInterventionDefaultConfiguration();
    // Unguided interventions can be changed to guided, so always run this code
    this.setUpUnlockingLessons();
    if (this.unlockingLessons.size > 0) {
      this.actualConditionalLessons.clear();
      this.fQuestionnaireConfiguration.controls.forEach((formControl) => {
        const lessonId = formControl.get('questionnaire_id').value;
        const unlockedByThis = this.unlockingLessons.get(lessonId);
        if (unlockedByThis !== undefined) {
          const feedbackControl = formControl.get('feedback_required');
          if (feedbackControl.value) {
            feedbackControl.setValue(false, { onlySelf: true });
          }
          feedbackControl.disable({ onlySelf: true, emitEvent: false });
        }
        if (formControl.get('unlock_type').value === 'CONDITIONAL') {
          const enabledControl = formControl.get('current_enabled');
          const enabled = enabledControl.value;
          this.actualConditionalLessons.set(lessonId, enabled);
          enabledControl.valueChanges.subscribe((enabled) => {
            const unlockType = formControl.get('unlock_type').value;
            const current = this.actualConditionalLessons.get(lessonId);
            const update = enabled && unlockType === 'CONDITIONAL';
            if (current !== update) {
              this.updateFeedbackRequired(lessonId, update);
            }
          });
          formControl.get('unlock_type').valueChanges.subscribe((value) => {
            const enabled = formControl.get('current_enabled').value;
            const current = this.actualConditionalLessons.get(lessonId);
            const update = enabled && value === 'CONDITIONAL';
            if (current !== update) {
              this.updateFeedbackRequired(lessonId, update);
            }
          });
        }
      });
    }
  }

  private updateFeedbackRequired(lessonId: number, update: boolean) {
    this.actualConditionalLessons.set(lessonId, update);
    this.fQuestionnaireConfiguration.controls.forEach((formControl) => {
      const unlockingId = formControl.get('questionnaire_id').value;
      const unlockedByThis = this.unlockingLessons.get(unlockingId);
      if (unlockedByThis !== undefined) {
        const shouldDisable = unlockedByThis.some(
          (id) => this.actualConditionalLessons.get(id),
        );
        const feedbackControl2 = formControl.get('feedback_required');
        if (shouldDisable && !feedbackControl2.disabled) {
          if (feedbackControl2.value) {
            feedbackControl2.setValue(false, { onlySelf: true });
          }
          feedbackControl2.disable({ onlySelf: true, emitEvent: false });
        } else if (!shouldDisable && feedbackControl2.disabled) {
          feedbackControl2.enable({ onlySelf: true, emitEvent: true });
        }
      }
    });
  }

  private setUpUnlockingLessons() {
    const configurations = this.defaultConfigurationReadOnly.questionnaire_configuration;
    const unlockingLessons = new Map<number, number[]>();
    for (const configuration of configurations) {
      if (configuration.unlock_type !== "conditional" || !configuration.condition) {
        continue;
      }
      const configurationId = Number(configuration.id);
      if (Array.isArray(configuration.condition)) {
        for (const condition of configuration.condition) {
          if (condition.diary_id == null) {
            const questionnaireId = Number(condition.questionnaire_id);
            const unlocks = unlockingLessons.get(questionnaireId);
            if (unlocks === undefined) {
              unlockingLessons.set(questionnaireId, [configurationId]);
            } else if (!unlocks.includes(configurationId)) {
              unlocks.push(configurationId);
            }
          }
        }
      } else {
        const condition = configuration.condition;
        if (condition.diary_id == null) {
          const questionnaireId = Number(condition.questionnaire_id);
          const unlocks = unlockingLessons.get(questionnaireId);
          if (unlocks === undefined) {
            unlockingLessons.set(questionnaireId, [configurationId]);
          } else if (!unlocks.includes(configurationId)) {
            unlocks.push(configurationId);
          }
        }
      }
    }
    this.unlockingLessons = unlockingLessons;
  }

  public changePosition(questionnaire: LessonInterface, _questionnaireIndex: number, pos: number): void {
    // Move value to position
    const questionnaireId = parseInt(questionnaire.id.toString(), 10);
    const currentPos = this.formCustomOrderSubject.value.findIndex((x) => x.questionnaire === questionnaireId);
    // const newpos = event.target.value;
    const newPos = currentPos + pos;
    const currentArray = this.formCustomOrderSubject.value.map((x) => x.questionnaire);
    const move = (array: number[], currentPos: number, newPos: number): number[] => {
      // If newpos happens to be longer than form_custom_order
      // if (newPos >= array.length) {
      //   let k = newPos - array.length + 1;
      //   while (k--) {
      //     // currentArray.push(undefined);
      //   }
      // }
      array.splice(newPos, 0, array.splice(currentPos, 1)[0]);
      return array;
    };
    const arrTemp = this.formCustomOrderSubject.value;
    this.formCustomOrderSubject.next(
      move(currentArray, currentPos, newPos).map((x) => ({
        questionnaire: x,
        enabled: arrTemp.find(a => a.questionnaire.toString() === x.toString()).enabled
      }))
    );
    // For each formcontrol update position value
    this.fQuestionnaireConfiguration.controls.forEach((formControl: AbstractControl) => {
      // Get questionnaire_id
      const id = parseInt(formControl.get('questionnaire_id').value.toString(), 10);
      const foundIndex = this.formCustomOrderSubject.value
        .findIndex((x) => x.questionnaire === id);
      formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });
      if (formControl.get('current_position').value.toString() === '0') {
        formControl.get('unlock_type').patchValue('ALWAYS', { onlySelf: true });
      }
    });

    this.sortByCurrentPosition();
    this.updateView.next('changePosition');
  }

  public sortByCurrentPosition(): void {
    this.fQuestionnaireConfiguration.controls.sort((a, b) => {
      const indexA = a.get('current_position').value.toString();
      const indexB = b.get('current_position').value.toString();
      return indexA - indexB;
    });
  }

  public replaceCurrentPosition(questionnaireId: string): string {
    let position = 0;
    for (const order of this.formCustomOrderSubject.value) {
      if (order.enabled) {
        position++;
        if (order.questionnaire.toString() === questionnaireId) {
          return 'Position ' + position;
        }
      } else if (order.questionnaire.toString() === questionnaireId) {
        return 'instance-update-form.form_create_instance_pos_not_selected';
      }
    }
    return 'instance-update-form.form_create_instance_pos_not_selected';
  }

  public checkEnabled(event: MatCheckboxChange, questionnaire: LessonInterface, l: number): void {
    const arrTemp = this.formCustomOrderSubject.value;
    arrTemp.forEach((value: { questionnaire: number; enabled: boolean }) => {
      if (value.questionnaire.toString() === questionnaire.id.toString()) {
        value.enabled = event.checked;
      }
    });
    this.formCustomOrderSubject.next(arrTemp);
    this.fQuestionnaireConfiguration.controls.forEach((formControl: AbstractControl) => {
      // Get questionnaire_id
      const id = parseInt(formControl.get('questionnaire_id').value.toString(), 10);
      const foundIndex = this.formCustomOrderSubject.value.findIndex((x) => x.questionnaire === id);
      const found = this.formCustomOrderSubject.value.find((x) => x.questionnaire === id);
      formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });
      formControl.get('current_enabled').patchValue(found.enabled, { onlySelf: true });
    });

    let isFirst = false;
    this.fQuestionnaireConfiguration.controls.forEach((formControl) => {
      // Get questionnaire_id
      const questionnaireId = formControl.get('questionnaire_id').value.toString();
      const foundValue = this.formCustomOrderSubject.value.find(
        (value) => value.questionnaire.toString() === questionnaireId,
      );
      const foundIndex = this.formCustomOrderSubject.value.findIndex(
        (value) => value.questionnaire.toString() === questionnaireId,
      );
      if (foundValue) {
        formControl.get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });
        if (foundValue.enabled && !isFirst) {
          isFirst = true;
          if (
            !formControl
              .get('unlock_type')
              .value.toString()
              .match(/^(ALWAYS|AT_DATE)$/)
          ) {
            formControl.get('unlock_type').patchValue('ALWAYS', { onlySelf: true });
          }
        }
      }
    });

    if (event.checked) {
      if (
        this.isFirstNotLocked(questionnaire.id) &&
        this.fQuestionnaireConfiguration.controls[l]
          .get('unlock_type')
          .value.toString()
          .match(/^(AFTER_PREVIOUS)$/)
      ) {
        this.fQuestionnaireConfiguration.controls[l].get('unlock_type').patchValue('ALWAYS', { onlySelf: true });
      }
      this.updateView.next('checkEnabled');
    } else {
      this.updateView.next('disabledEnabled');
    }
    this.sortByCurrentPosition();
  }

  public isFirstNotLocked(questionnaireId: number): boolean {
    for (const value of this.formCustomOrderSubject.value) {
      const qreId = value.questionnaire.toString();
      const found = this.lockedQuestionnaireIds.some(id => id.toString() === qreId);
      if (!found) {
        return questionnaireId.toString() === qreId;
      }
    }
    return false;
  }

  public lockPosition(formcontrol: AbstractControl): boolean {
    if (formcontrol) {
      const questionnaireId = formcontrol.get('questionnaire_id').value.toString();
      const found = this.lockedQuestionnaireIds.find(id => id.toString() === questionnaireId);
      return found !== undefined;
    }
    return false;
  }

  public showUnlockHint(questionnaireId: number): Observable<boolean> {
    return this.updateView.pipe(
      mergeMap(() => {
        const qreId = questionnaireId.toString();
        const foundControl = this.fQuestionnaireConfiguration.controls.find(
          (formcontrol: AbstractControl) =>
            formcontrol.get('questionnaire_id').value.toString() === qreId,
        );
        if (!foundControl || foundControl.get('unlock_type').value !== 'AFTER_PREVIOUS') {
          return of(false);
        }
        // Previous position based on form_custom_order
        const previousEnabledQuestionnaire =
          this.helper.getPreviousEnabledQuestionnaire(qreId, this.formCustomOrderSubject.value);
        if (previousEnabledQuestionnaire !== undefined) {
          const foundPredecessor = this.fQuestionnaireConfiguration.controls.find(
            (formcontrol: AbstractControl) =>
              formcontrol.get('questionnaire_id').value.toString() === previousEnabledQuestionnaire,
          );
          if (foundPredecessor && foundPredecessor.get('feedback_required').value) {
            return of(true);
          }
        }
        return of(false);
      })
    );
  }

  getAlreadyStarted(questionnaireId: number): number {
    if (this.instance.attributes.progress.active_questionnaires) {
      return this.instance.attributes.progress.active_questionnaires.find(
        (value: number) => value.toString() === questionnaireId.toString()
      );
    } else {
      return null;
    }
  }

  public setDate(_questionnaireId: number, i: number): Date {
    const startsAt = this.helperService.convertStringToUnixTimestamp(this.f.starts_at.value);
    const unlockDate = this.helperService.convertStringToUnixTimestamp(
      this.fQuestionnaireConfiguration.controls[i].get('unlock_date').value
    );
    if (unlockDate < startsAt) {
      return new Date(this.f.starts_at.value);
    } else {
      // TODO Recalculate min date
      return new Date(this.f.starts_at.value);
    }
  }

  public openDialogDiaryPreview(questionnaireId: number, studyId: number, diaryPageColor: string) {
    this.helperDialog
      .openDialogDiaryPreview(questionnaireId, studyId, diaryPageColor)
      .afterClosed()
      .subscribe((_result) => {});
  }

  public openDialogInterventionLesson(questionnaireId: number, studyId: number): void {
    this.helperDialog
      .openDialogInterventionLesson(questionnaireId, studyId)
      .afterClosed()
      .subscribe((_result) => {});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getCondition(questionnaireId: number): string {
    const foundConfig: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaireId);
    if (foundConfig !== null && foundConfig.condition) {
      if (Array.isArray(foundConfig.condition)) {
        if (foundConfig.condition.length > 0) {
          return foundConfig.condition.reduce((prev, curr) => prev + ' (' + curr.description + ')', '');
        } else {
          return null;
        }
      } else {
        return foundConfig.condition.description ? foundConfig.condition.description : JSON.stringify(foundConfig.condition);
      }
    }
    return null;
  }

  public hasDefaultConditional(questionnaire_id: number): boolean {
    const config: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(questionnaire_id);
    return config ? config.unlock_type.toLowerCase() === 'conditional' : false;
  }

  public openDialogSkill(skillId: number) {
    const dialogRef = this.helperDialog.openDialogSkill(skillId, this.skills, this.intervention);
    if (dialogRef) {
      dialogRef.afterClosed().subscribe((_result) => {});
    }
  }

  // Set default configuration
  private getInterventionDefaultConfiguration(): void {
    this.intervention$ = this.store.select(getCollabInterventionById(this.intervention.id));
    this.store.dispatch({ type: InterventionActionTypes.getInterventionsOfCollabStudyType, payload: { typeOfParentStudy: 'study' } });
    this.subscriptions.push(
      this.intervention$
        .pipe(
          filter((intervention: InterventionInterface | undefined) => !!intervention),
          take(1)
        )
        .subscribe((intervention: InterventionInterface) => {
          this.intervention = intervention;
          this.defaultConfiguration = this.intervention.attributes.default_configuration;
        })
    );
  }

  private getDefaultConfigurationOfQuestionnaire(questionnaireId: number): InterventionConfigurationInterface {
    const found = this.defaultConfiguration.questionnaire_configuration.find(
      (questionnaire: InterventionConfigurationInterface) => questionnaire.id.toString() === questionnaireId.toString()
    );
    return found ? found : null;
  }
}
