<ng-container [formGroup]="instanceFormType">
  <div formArrayName="questionnaire_configuration">
    <ul *ngIf="fQuestionnaireConfiguration.controls.length > 0; else elseNoLessonSelected">
      <li
        [attr.id]="'questionnaire_' + q.get('questionnaire_id').value"
        *ngFor="let q of fQuestionnaireConfiguration.controls; let i = index"
        [ngClass]="{'deselected-background': !(fQuestionnaireConfiguration.controls[i].get('current_enabled').value)}"
        class="list-group-item flex-column align-items-start"
      >
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="d-flex flex-column justify-content-between">
              <div class="p-2">
                <button
                  [attr.id]="'btnChangePositionUp_' + q.get('questionnaire_id').value"
                  (click)="changePosition(helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, questionnaires), i, -1)"
                  *ngIf="fQuestionnaireConfiguration.controls[i].get('current_position').value.toString() !== '0' && fQuestionnaireConfiguration.controls[i].get('current_position').value.toString() !== '-1'"
                  class="btn btn-link"
                  role="button"
                >
                  <fa-icon [icon]="faChevronUp"></fa-icon>
                </button>
              </div>

              <div class="p-2">
                <strong
                  >{{replaceCurrentPosition(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value.toString()) |
                  translate}}</strong
                >
              </div>

              <div [formGroupName]="i" class="custom-control custom-checkbox mb-3 p-2">
                <mat-checkbox
                  [attr.id]="'checkboxEnableLesson' + q.get('questionnaire_id').value"
                  (change)="checkEnabled($event, helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, questionnaires), i)"
                  [attr.disabled]="(((formCustomOrderSubject | async).length === 1 && (fQuestionnaireConfiguration.controls[i].get('current_enabled').value))) || (lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                  class="custom-control-input"
                  formControlName="current_enabled"
                  type="checkbox"
                >
                  {{'instance-update-form.form_create_instance_customize_enabled' | translate}}
                </mat-checkbox>
              </div>
              <div class="p-2">
                <button
                  [attr.id]="'btnChangePositionDown_' + q.get('questionnaire_id').value"
                  (click)="changePosition(helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, questionnaires), i, 1)"
                  *ngIf="fQuestionnaireConfiguration.controls[i].get('current_position').value.toString() !== ((formCustomOrderSubject | async)?.length - 1).toString() && fQuestionnaireConfiguration.controls[i].get('current_position').value.toString() !== '-1'"
                  class="btn btn-link"
                  role="button"
                >
                  <fa-icon [icon]="faChevronDown"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-9">
            <p>
              <strong>
                <span class="font-weight-bold"
                  >{{helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value,
                  questionnaires).attributes.position}} .
                  {{helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value,
                  questionnaires).attributes.title}} -
                  {{helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value,
                  questionnaires).attributes.name}}</span
                >
              </strong>
              <button
                [attr.id]="'btnOpenDialogInterventionLesson' + q.get('questionnaire_id').value"
                (click)="openDialogInterventionLesson(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, intervention.attributes.study_id)"
                mat-button
              >
                <fa-icon [icon]="faEye"></fa-icon>
              </button>
            </p>
            <div [formGroupName]="i">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "instance-update-form.form_create_instance_select_unlock_type" | translate }}</mat-label>

                <div
                  *ngIf="
                hasDefaultConditional(
                  fQuestionnaireConfiguration.controls[i].get(
                    'questionnaire_id'
                  ).value
                );
              then thenUnlockTypeFirstNew;
              else elseUnlockFirstType
            "
                ></div>

                <ng-template #thenUnlockTypeFirstNew>
                  <mat-select
                    [attr.id]="'selectUnlockTypes_' + q.get('questionnaire_id').value"
                    *ngIf="fQuestionnaireConfiguration.controls[i].get('current_position').value.toString() === '0'; else elseSelectedUnlockTypes"
                    formControlName="unlock_type"
                    required
                    [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i]))"
                  >
                    <mat-option
                      [attr.id]="'item_' + type + '_' + q.get('questionnaire_id').value"
                      *ngFor="let type of unlockTypeFirstNew"
                      [attr.disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                      [value]="type"
                      >{{('instance-update-form.' + type.toLowerCase()) | translate}}</mat-option
                    >
                  </mat-select>
                </ng-template>
                <ng-template #elseUnlockFirstType>
                  <mat-select
                    [attr.id]="'selectUnlockTypes_' + q.get('questionnaire_id').value"
                    *ngIf="fQuestionnaireConfiguration.controls[i].get('current_position').value.toString() === '0'; else elseSelectedUnlockTypes"
                    formControlName="unlock_type"
                    required
                    [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i]))"
                  >
                    <mat-option
                      [attr.id]="'item_' + type + '_' + q.get('questionnaire_id').value"
                      *ngFor="let type of unlockTypeFirst"
                      [attr.disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                      [value]="type"
                      >{{('instance-update-form.' + type.toLowerCase()) | translate}}</mat-option
                    >
                  </mat-select>
                </ng-template>

                <ng-template #elseSelectedUnlockTypes>
                  <div
                    *ngIf="isFirstNotLocked(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value); then thenShowFirstNotLocked else elseShowRest"
                  ></div>
                  <ng-template #thenShowFirstNotLocked>
                    <div
                      *ngIf="
                      hasDefaultConditional(
                        fQuestionnaireConfiguration.controls[i].get(
                          'questionnaire_id'
                        ).value
                      );
                      then thenUnlockTypeNew;
                      else elseUnlockType
                    "
                    ></div>
                    <ng-template #thenUnlockTypeNew>
                      <mat-select
                        [attr.id]="'selectUnlockTypes_' + q.get('questionnaire_id').value"
                        formControlName="unlock_type"
                        required
                        [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i]))"
                      >
                        <mat-option
                          [attr.id]="'item_' + type + '_' + q.get('questionnaire_id').value"
                          *ngFor="let type of unlockTypeNew"
                          [attr.disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                          [value]="type"
                          >{{('instance-update-form.' + type.toLowerCase()) | translate}}</mat-option
                        >
                      </mat-select>
                    </ng-template>
                    <ng-template #elseUnlockType>
                      <mat-select
                        [attr.id]="'selectUnlockTypes_' + q.get('questionnaire_id').value"
                        formControlName="unlock_type"
                        required
                        [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i]))"
                      >
                        <mat-option
                          [attr.id]="'item_' + type + '_' + q.get('questionnaire_id').value"
                          *ngFor="let type of unlockTypeNext"
                          [attr.disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                          [value]="type"
                          >{{('instance-update-form.' + type.toLowerCase()) | translate}}</mat-option
                        >
                      </mat-select>
                    </ng-template>
                  </ng-template>
                  <ng-template #elseShowRest>
                    <div
                      *ngIf="
                      hasDefaultConditional(
                        fQuestionnaireConfiguration.controls[i].get(
                          'questionnaire_id'
                        ).value
                      );
                      then thenUnlockTypeNew;
                      else elseUnlockType
                    "
                    ></div>
                    <ng-template #thenUnlockTypeNew>
                      <mat-select
                        [attr.id]="'selectUnlockTypes_' + q.get('questionnaire_id').value"
                        [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i]))"
                        formControlName="unlock_type"
                        required
                      >
                        <mat-option
                          [attr.id]="'item_' + type + '_' + q.get('questionnaire_id').value"
                          *ngFor="let type of unlockTypeNew"
                          [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                          [value]="type"
                          >{{('instance-update-form.' + type.toLowerCase()) | translate}}</mat-option
                        >
                      </mat-select>
                    </ng-template>
                    <ng-template #elseUnlockType>
                      <mat-select
                        [attr.id]="'selectUnlockTypes_' + q.get('questionnaire_id').value"
                        [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i]))"
                        formControlName="unlock_type"
                        required
                      >
                        <mat-option
                          [attr.id]="'item_' + type + '_' + q.get('questionnaire_id').value"
                          *ngFor="let type of unlockType"
                          [disabled]="(lockPosition(fQuestionnaireConfiguration.controls[i])) ? '' : null"
                          [value]="type"
                          >{{('instance-update-form.' + type.toLowerCase()) | translate}}</mat-option
                        >
                      </mat-select>
                    </ng-template>
                  </ng-template>
                </ng-template>
                <mat-hint
                  class="text-info"
                  *ngIf="(showUnlockHint(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value) | async)"
                >
                  {{'instance-update-form.form_create_instance_unlocks_next' | translate}}
                </mat-hint>
                <mat-hint class="text-info" *ngIf="fQuestionnaireConfiguration.controls[i].get('unlock_type').value === 'AT_DATE'">
                  <span
                    *ngIf="fQuestionnaireConfiguration.controls[i].get('unlock_days_after_start').value > 0; then thenShowDays else elseShowImmediately"
                  ></span>
                  <ng-template #thenShowDays>
                    {{'instance-update-form.form_create_instance_unlock_at_days_part1' | translate}}
                    {{(fQuestionnaireConfiguration.controls[i].get('unlock_days_after_start').value > 1) ?
                    fQuestionnaireConfiguration.controls[i].get('unlock_days_after_start').value + ' ' +
                    ('instance-update-form.form_create_instance_unlock_at_days_part2_2' | translate) :
                    ('instance-update-form.form_create_instance_unlock_at_days_part2_1' | translate)}}
                    {{'instance-update-form.form_create_instance_unlock_at_days_customize' | translate}}
                  </ng-template>
                  <ng-template #elseShowImmediately>
                    {{'instance-update-form.form_create_instance_unlock_at_immediately' | translate}}
                    {{'instance-update-form.form_create_instance_unlock_at_days_customize' | translate}}
                  </ng-template>
                </mat-hint>
                <!--
                <mat-hint class="text-info" *ngIf="fQuestionnaireConfiguration.controls[i].get('unlock_type').value === 'CONDITIONAL'">
                  <span>{{getCondition(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value)}}</span>
                </mat-hint>
              -->
              </mat-form-field>

              <p class="text-info small break" *ngIf="fQuestionnaireConfiguration.controls[i].get('unlock_type').value === 'CONDITIONAL'">
                <span>{{getCondition(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value)}}</span>
              </p>

              <mat-form-field
                class="full-width"
                appearance="outline"
                class="mt-4"
                *ngIf="fQuestionnaireConfiguration.controls[i].get('unlock_type').value === 'AT_DATE'"
              >
                <input
                  matInput
                  [attr.id]="'inputDate' + q.get('questionnaire_id').value"
                  [ngxMatDatetimePicker]="picker"
                  [placeholder]="helper.displayReadableDateTimeValue(fQuestionnaireConfiguration.controls[i].get('unlock_date').value)"
                  formControlName="unlock_date"
                  [disabled]="false"
                />
                <!--
                  [min]="setDate(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, i)" -->
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  [attr.id]="'timepicker' + q.get('questionnaire_id').value"
                  #picker
                  [showSpinners]="showSpinners"
                  [showSeconds]="showSeconds"
                  [stepHour]="stepHour"
                  [stepMinute]="stepMinute"
                  [stepSecond]="stepSecond"
                  [touchUi]="touchUi"
                  [color]="color"
                  [enableMeridian]="enableMeridian"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>

              <!-- Is feedback for lesson required? -->
              <div *ngIf="this.f.intervention_type.value === 'GUIDED'" class="custom-control custom-checkbox mb-3">
                <mat-checkbox
                  [disabled]="lockPosition(fQuestionnaireConfiguration.controls[i])"
                  [attr.id]="'checkboxFeedbackRequired' + q.get('questionnaire_id').value"
                  formControlName="feedback_required"
                >
                  {{ 'instance-update-form.form_create_instance_customize_feedback' | translate }}
                </mat-checkbox>
                <span
                  *ngIf="(
                    !lockPosition(fQuestionnaireConfiguration.controls[i]) &&
                    fQuestionnaireConfiguration.controls[i].get('feedback_required').disabled
                  )"
                >
                  <mat-hint class="text-danger">
                    ({{ 'instance-creation-form.form_create_instance_customize_feedback_not_required' | translate }})
                  </mat-hint>
                </span>
              </div>

              <p *ngIf="getAlreadyStarted(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value)" class="text-danger">
                {{ 'instance-update-form.is_active' | translate }}
              </p>

              <!-- Has this lesson been finished? -->
              <div *ngIf="lockPosition(fQuestionnaireConfiguration.controls[i])" class="text-success">
                {{ 'instance-update-form.form_update_lesson_already_finished' | translate }}
              </div>
            </div>

            <div *ngIf="helper.hasUnlockDiaries(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, questionnaires)">
              <mat-divider></mat-divider>
              <p class="py-2">{{'instance-creation-form.unlocks_diaries' | translate}}:</p>
              <div class="row">
                <div
                  *ngFor="let diary of helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, questionnaires).attributes.unlock_diaries"
                >
                  <div *appVariable="helper.findArrObjById(diary, diaries) as selectedDiary" class="col-12">
                    <fa-icon [icon]="faBook"></fa-icon>
                    {{selectedDiary !== null ? selectedDiary.attributes.name : ('lesson.diary_not_found' | translate)}}

                    <div *ngIf="selectedDiary?.attributes?.questionnaire_id">
                      <button
                        [attr.id]="'btnOpenDialogDiaryPreview_' + selectedDiary.attributes.questionnaire_id"
                        mat-button
                        (click)="openDialogDiaryPreview(selectedDiary.attributes.questionnaire_id, selectedDiary.attributes.study_id, selectedDiary.attributes.page_color)"
                      >
                        <fa-icon [icon]="faEye"></fa-icon> {{'instance-creation-form.show_diary_preview' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              *appVariable="helper.findArrObjById(fQuestionnaireConfiguration.controls[i].get('questionnaire_id').value, questionnaires) as selectedQuestionnaire"
            >
              <div *ngIf="selectedQuestionnaire.attributes?.skills">
                <div *ngIf="selectedQuestionnaire.attributes.skills.length > 0; then thenShowSkills"></div>
                <ng-template #thenShowSkills>
                  <div class="button-row">
                    <button
                      [attr.id]="'btnOpenDialogSkill_' + skillId"
                      *ngFor="let skillId of selectedQuestionnaire.attributes.skills; last as isLast"
                      [ngClass]="{'spacing-right': !isLast}"
                      class="mb-2"
                      (click)="openDialogSkill(skillId)"
                      mat-button
                    >
                      <span [ngStyle]="helperSkill.getColorStyle(skillId, skills)"
                        ><fa-icon [icon]="faSquare"></fa-icon> {{helper.findArrObjById(skillId, skills) !== null ?
                        helper.findArrObjById(skillId, skills).attributes.title : ('lesson.skill_not_found' | translate)}}</span
                      >
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ng-template #elseNoLessonSelected>
      <li class="list-group-item text-center">{{'instance-update-form.form_create_instance_custom_order_no_item' | translate}}</li>
    </ng-template>
  </div>
</ng-container>
